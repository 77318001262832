<template>
  <div>
    <!--form add & update faculty-->
    <ek-dialog
      btnText="كلية جديدة"
      ref="dialog"
      :isEdit="!!facultyDto.id"
      :title="facultyDto.id ? 'تعديل  كلية' : 'اضافة كلية '"
      @delete="deleteFac(facultyDto.id)"
      placeholder="ابحث عن كلية محدد"
      @ok="submitForm"
      @close="resetForm"
      @search="setSearch"
    >
      <template #body>
        <validationObserver ref="facultyForm">
          <ek-input-text
            v-model="facultyDto.name"
            name="اسم "
            placeholder="ادخل اسم الكلية"
            label="اسم الكلية"
            :rules="[{ type: 'required', message: 'اسم الجامعة مطلوب' }]"
          ></ek-input-text>
          <ek-input-select
            name="nameUniversity"
            placeholder="حدد الجامعة التابعة لها"
            label="الجامعة التابعة لها  "
            :options="universityName"
            v-model="facultyDto.universityId"
          ></ek-input-select>

          <template v-if="facultyDto.id && facultyDto.departments.length">
            <label>الفروع</label>

            <b-list-group v-if="facultyDto.departments.length">
              <b-list-group-item v-for="item in facultyDto.departments" :key="item">
                {{
                  universityName
                    .find((u) => u.id == facultyDto.universityId)
                    .faculties.find((f) => f.id === facultyDto.id)
                    .departments.find((d) => d.id == item).name
                }}
              </b-list-group-item>
            </b-list-group>
          </template>

          <b-form-checkbox v-if="!facultyDto.id" v-model="select"
            >فرع عام</b-form-checkbox
          >
          <ek-input-text
            v-if="select == 1 && !facultyDto.id"
            v-model="facultyDto.yearCount"
            name="count "
            placeholder="ادخل  عدد سنوات الفرع"
            label="عدد سنوات الفرع العام"
          ></ek-input-text>
        </validationObserver>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      facultyDto: (state) => state.faculty.facultyDto,
      isDialogOpen: (state) => state.faculty.isDialogOpen,
      universityName: (state) => state.university.universityName,
    }),
  },
  data() {
    return {
      select: 0,
    };
  },
  methods: {
    ...mapActions(["addFaculty", "updateFaculty"]),
    //submit add & update faculty
    submitForm() {
      this.$refs.facultyForm.validate().then((suc) => {
        if (suc) {
          if (this.facultyDto.id) {
            this.updateFaculty(this.facultyDto);
            this.$refs.dialog.close();
          } else {
            this.addFaculty(this.facultyDto)
              .then((data) => {
                console.log(data);
                this.$refs.dialog.close();
              })
              .catch(() => {});
          }
        }
      });
    },
    deleteFac(id) {
      this.$store.dispatch("deleteFaculties", [id]);
    },
    resetForm() {
      this.$refs.facultyForm.reset();
      this.$store.commit("SET_FACULTY_DTO");
      this.$store.commit("IS_DIALOG_OPEN", false);
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name", "facultiesCount", "studentsCount"],
        query,
      });
    },
  },
  watch: {
    isDialogOpen(nv) {
      if (nv) {
        this.$refs.dialog.open();
      }
    },
  },
};
</script>

<style></style>
